import React from "react";
import { Box, Typography } from "@mui/material/";
import BG from "../Assets/HeroNoText.gif";
import "../App.css";

function Hero() {
  const styling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${BG})`,
    backgroundSize: "100% 100%", // Adjusted property for showing entire image
    backgroundRepeat: "no-repeat",
    // Other styling properties

    // Responsive Styling
    "@media (max-width: 768px)": {
      height: "100vh",
    },
    "@media (max-width: 480px)": {
      height: "100vh",
    },
  };

  const fontStyling = {
    fontFamily: "ThereBrat",
    fontSize: "12vw",
    color: "#5448C8",
  };
  
  return (
    <div>
      <Box sx={styling}>
        <Typography variant="h1" style={fontStyling}>
        1i11iana
        </Typography>
      </Box>
    </div>
  );
}

export default Hero;
