import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  TextareaAutosize,
  Modal,
} from "@mui/material";
import CircularProgress from "@mui/joy/CircularProgress";
import emailjs from "emailjs-com";
import Checked from "../Assets/checked.png";
import Canceled from "../Assets/cancel.png";


function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const headingStyle ={
    fontFamily: "Montserrat, sans-serif",
    textAlign: 'center'
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    boxShadow: 24,
    p: 4,
  };

  const loadingOverlay = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent dark background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
  };

  const modalLayout = {
    display: "flex",
    alignItems: "center",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Only trim spaces for firstName, lastName, email, and phoneNumber
    const trimmedValue = [
      "firstName",
      "lastName",
      "email",
    ].includes(name)
      ? value.trim()
      : value;

    setFormData({
      ...formData,
      [name]: trimmedValue,
    });

    // Clear the error for the field when the user types
    if (name === "firstName" || name === "lastName") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  //handle open modal
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  //handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.firstName || !formData.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!formData.lastName || !formData.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.message || !formData.message.trim()) {
      errors.message = "Message is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const emailData = {
        from_name: formData.firstName + " " + formData.lastName,
        from_email: formData.email,
        to_name: "LILLIANA REINOSO",
        message: formData.message,
      };

      emailjs
        .send(
          "service_kpkfqjh", // Replace with your Service ID
          "template_pfq6nqa", // Replace with your Template ID
          emailData,
          "YEISN36TDz5lZN4EE",
          {
            from_name: formData.firstName + " " + formData.lastName,
            from_email: formData.email,
            to_name: "LILLIANA REINOSO", // Replace with the recipient's name
            message: formData.message,
          }
        )
        .then(
          function (response) {
            console.log("Email sent successfully:", response);
            setIsSuccessful(true);
            handleModalOpen();
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
          },
          function (error) {
            console.error("Email sending failed:", error);
            setIsSuccessful(false);
            handleModalOpen();
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: 16 }}>
        <Typography variant="h4" marginBottom={2} style={headingStyle} >Send Me an Email</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                name="firstName"
                sx={{ width: "100%" }}
                value={formData.firstName}
                onChange={handleInputChange}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                name="lastName"
                sx={{ width: "100%" }}
                value={formData.lastName}
                onChange={handleInputChange}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                sx={{ width: "100%" }}
                value={formData.email}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                minRows={4}
                label="Message"
                name="message"
                placeholder="Write your message here "
                fullwidth="true"
                style={{
                  width: "100%",
                  borderColor: errors.message ? "red" : "inherit",
                }}
                value={formData.message}
                onChange={handleInputChange}
              />
              {errors.message && (
                <div style={{ color: "red" }}>{errors.message}</div>
              )}
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                alignItems="center"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper sx={boxStyle}>
          {isSuccessful === true && (
            <div style={modalLayout}>
              <img
                alt="Success"
                src={Checked}
                width="100"
                height="100"
                style={{ marginRight: "10%" }}
              ></img>
              <p>Your email sent sucesfully.</p>
            </div>
          )}
          {isSuccessful === false && (
            <div style={modalLayout}>
              <img
                alt="Denied"
                src={Canceled}
                width="100"
                height="100"
                style={{ marginRight: "10%" }}
              ></img>
              <p>Unable to send email. Try agian later.</p>
            </div>
          )}
        </Paper>
      </Modal>
      {isLoading && (
        <div style={loadingOverlay}>
          <CircularProgress variant="solid" size="lg" />
        </div>
      )}
    </Container>
  );
}

export default ContactForm;
