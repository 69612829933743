import React, { useState } from "react";
import "../App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

const MenuBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const themeOptions = createTheme({
    typography: {
      fontFamily: "StrangerThings, sans-serif",
      h6: {
        fontFamily: "StrangerThings, sans-serif",
      },
    },
  });

  return (
    <ThemeProvider theme={themeOptions}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: "#000000",
            "@media screen and (max-width: 600px)": {
              flexDirection: "column",
            },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <Button color="inherit">
                <div
                  style={{
                    fontFamily: "ThereBrat",
                    fontSize: "2em",
                    color: "white",
                  }}
                >
                  1i11iana
                </div>
              </Button>
            </Link>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: "block", md: "none" } }}
              onClick={handleClick} // open the dropdown menu on click
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Link to="/artistStatement">
                <Button
                  color="inherit"
                  style={{
                    textTransform: "none",
                    fontSize: "1.5rem",
                    color: "#5448C8",
                  }}
                >
                  Artist Statement
                </Button>
              </Link>
              <Link to="/portfolio">
                <Button
                  color="inherit"
                  style={{
                    textTransform: "none",
                    fontSize: "1.5rem",
                    color: "#39FF14",
                  }}
                >
                  portfolio
                </Button>
              </Link>
              <Link to="/resume">
                <Button
                  color="inherit"
                  style={{
                    textTransform: "none",
                    fontSize: "1.5rem",
                    color: "#FFF01F",
                  }}
                >
                  Resume
                </Button>
              </Link>
              <Link to="/contactMe">
                <Button
                  color="inherit"
                  style={{
                    textTransform: "none",
                    fontSize: "1.5rem",
                    color: "#5448C8",
                  }}
                >
                  contact me
                </Button>
              </Link>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose} // close the dropdown menu on click outside
            >
              <Link to="/artistStatement">
                <MenuItem onClick={handleClose}>artist statement</MenuItem>
              </Link>
              <Link to="/portfolio">
                <MenuItem onClick={handleClose}>portfilio</MenuItem>
              </Link>
              <Link to="/resume">
                <MenuItem onClick={handleClose}>Resume</MenuItem>
              </Link>
              <Link to="/contactMe">
                <MenuItem onClick={handleClose}>contact me</MenuItem>
              </Link>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};

export default MenuBar;
