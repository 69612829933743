import Hero from './components/Hero';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuBar from './components/MenuBar';
import ArtistStatement from './components/ArtistStatement';
import Portfilio from './components/Portfilio';
import ContactMe from './components/ContactMe'
import Resume from './components/Resume';


function App() {
  return (
    <BrowserRouter>
    <div>
      <MenuBar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/artistStatement" element={<ArtistStatement />} />
        <Route path="/portfolio" element={<Portfilio />} />
        <Route path="/contactMe" element={<ContactMe />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </div>
  </BrowserRouter>
  );
}

export default App;
