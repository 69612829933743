import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Flag from "../Assets/Flag.jpg";
import Pyramid from "../Assets/pyramid.jpg";
import ContactForm from "./ContactForm";
import Votive from "../Assets/votivepng.png";
import Loot from "../Assets/loot.jpg";
import BG from "../Assets/emailBg.gif";
import JaJa from "../Assets/jaja.png";

const ContactMe = () => {
  const headerStyle = {
    color: "#5448C8",
    fontFamily: "ThereBrat",
  };
  const images = [Pyramid, Votive, Loot, JaJa, Flag];

  return (
    <Box
      style={{
        backgroundColor: "black",
        width: "100vw",
        height: "100vh",
        background: `url(${BG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box>
        <Typography variant="h1" align="center" style={headerStyle}>
          Keep in Touch
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ margin: "2%" }}>
        <Grid item xs={6}>
          {/* react-material-ui-carousel */}
          <Carousel>
            {images.map((image, index) => (
              <Paper
                elevation={0}
                key={index}
                style={{ height: "60vh", width: "100%" }}
              >
                <img
                  src={image}
                  alt={`carousel-img-${index}`}
                  style={{ width: "100%", height: "100%" }}
                />
              </Paper>
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={6}>
          {/* img */}
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactMe;
