import React from 'react';
import '../App.css';


const Resume = () => {
  const pdfUrl = process.env.PUBLIC_URL + '/pdfs/ReinosoLilliana.pdf';
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        title="PDF Viewer"
        src={pdfUrl}
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
}

export default Resume;