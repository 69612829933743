import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  ImageList,
  ImageListItem,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material/";
import { useMediaQuery, useTheme } from "@mui/material";
import FiveForTips from "../Assets/5ForTips.jpg";
import Bananas from "../Assets/bananas.jpg";
import Flag from "../Assets/Flag.jpg";
import Nips from "../Assets/Nips.jpg";
import NoFats from "../Assets/noFats.jpg";
import Bastards from "../Assets/bsatard.jpg";
import Covid from "../Assets/covid.jpg";
import Pepto from "../Assets/pepto.jpg";
import Dan from "../Assets/Dan.jpg";
import Wall from "../Assets/wall.jpg";
import Clay from "../Assets/clay.jpg";
import School from "../Assets/school.jpg";
import Votive from "../Assets/votivepng.png";
import Loot from "../Assets/loot.jpg";
import Pyramid from "../Assets/pyramid.jpg";
import JaJa from "../Assets/jaja.png";
import N95 from "../Assets/N95.jpg";
import Ponder from "../Assets/Pondering.jpg";
import Stand from "../Assets/stand.jpg";
import Rodney from "../Assets/Rodney.jpg";
import Parthenon from "../Assets/Parthenon .jpg";
import QuiltOne from "../Assets/StolenQuilt0ne.jpg";
import QuiltTwo from "../Assets/StolenQuiltTwo.jpg";
import QuiltThree from "../Assets/StolenQuiltThree.jpg";
import Pervert from "../Assets/PerverseMausoleum.jpg";
import Woman from "../Assets/Woman.jpg";
import Wire from "../Assets/Wire.jpg";
import Amanda from "../Assets/amada.jpg";
import DryPoint from "../Assets/dryoint.jpg";
import DryPointPrint from '../Assets/drypointPrint.jpg';
import Mimi from '../Assets/Mini.jpg';
import Rolleers from '../Assets/rollers.jpg'

const Portfilio = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(itemData[1]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Change the value after 5 sec
      setIsLoading(false);
    }, 5000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  const handleItemClick = (item) => {
    setIsModalOpen(true);
    console.log(item);
    setModalData(item);
  };

  const modalLayout = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const innterModalLayout = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "80%",
  };

  const modalHeadingStyle = {
    fontFamily: "StrangerThings, sans-serif",
    textAlign: "center",
  };

  const modalSpecs = {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
  };

  const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    boxShadow: 24,
    p: 4,
    width: "80%", // Adjust the width as needed
  };

  const boxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // 100% of viewport height
    width: "100%", // 100% of viewport width
    overflowY: "scroll",
    backgroundColor: "black",
  };

  const progessStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // 100% of viewport height
    width: "100vw", // 100% of viewport width
    overflowY: "scroll",
  };

  return (
    <Box sx={boxStyle}>
      {isLoading ? (
        <Box sx={progessStyle}>
          <CircularProgress />
        </Box>
      ) : (
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                onClick={() => handleItemClick(item)}
                onMouseOver={(e) => (e.currentTarget.style.opacity = 0.3)}
                onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer", // Add this style to indicate it's clickable
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <Modal open={isModalOpen} onClose={handleModalClose}>
        {isSmallScreen ? (
          <Paper sx={modalBoxStyle}>
            <Box sx={innterModalLayout}>
              <Typography variant="h4" style={modalHeadingStyle}>
                {modalData.title}
              </Typography>
              <Typography variant="body1" style={modalSpecs}>
                {modalData.year}
              </Typography>
              <Typography variant="body3" style={modalSpecs}>
                {modalData.form}
              </Typography>
              <Typography variant="body1" marginBottom={2} style={modalSpecs}>
                {modalData.size}
              </Typography>
              <Typography variant="body1" marginBottom={2} style={modalSpecs}>
                {modalData.trailer}
              </Typography>
              {modalData.link ? (
                <Typography variant="body1" marginBottom={2} style={modalSpecs}>
                  <a
                    href={modalData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watch on YouTube
                  </a>
                </Typography>
              ) : (
                <div></div>
              )}
              <img
                alt=" "
                src={modalData.img}
                style={{ width: "65vw", height: "auto", marginBottom: "2%" }}
              />
              <Box
                maxHeight="200px"
                overflow="auto"
                maxWidth="70%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <Typography variant="body2">{modalData.description}</Typography>
              </Box>
            </Box>
          </Paper>
        ) : (
          <Paper sx={modalBoxStyle}>
            <Box sx={modalLayout}>
              <Box sx={innterModalLayout}>
                <Typography variant="h4" style={modalHeadingStyle}>
                  {modalData.title}
                </Typography>
                <Typography variant="body1" style={modalSpecs}>
                  {modalData.year}
                </Typography>
                <Typography variant="body3" style={modalSpecs}>
                  {modalData.form}
                </Typography>
                <Typography variant="body1" marginBottom={2} style={modalSpecs}>
                  {modalData.size}
                </Typography>
                <Typography variant="body1" marginBottom={2} style={modalSpecs}>
                  {modalData.trailer}
                </Typography>
                {modalData.link ? (
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    style={modalSpecs}
                  >
                    <a
                      href={modalData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Watch on YouTube
                    </a>
                  </Typography>
                ) : (
                  <div></div>
                )}
                <Box
                  maxHeight="200px"
                  overflow="auto"
                  maxWidth="80%"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "12px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  }}
                >
                  <Typography variant="body2">
                    {modalData.description}
                  </Typography>
                </Box>
              </Box>
              <img
                alt=" "
                src={modalData.img}
                style={{ width: "45vw", height: "auto", marginLeft: "2%" }}
              />
            </Box>
          </Paper>
        )}
      </Modal>
    </Box>
  );
};

const itemData = [
  {
    img: FiveForTips,
    title: "Five Dollars for Tips",
    year: "2021",
    form: "Screen-print with silver foil on 80lb cardstock",
    size: '14" x 11"',
    description:
      "                                                                          ",
  },
  {
    img: Nips,
    year: "2020",
    form: "digital photography",
    size: '26.67" x 40"',
    title: "Thermal Nips",
    description:
      "This image is taken from my project exploring Instagram’s ban on “female-presenting” nipples entitled For" +
      " Your AI’s Only. The image was taken down upon posting. This specific image reveled the transphobic" +
      " nature of the platform’s nipple ban, as the model for this photo had been on testosterone for several months.",
  },
  {
    img: Flag,
    year: "2022",
    form: "dyed cotton and polyester fabric accompanied by screen-prints.",
    size: "29”x 600”",
    title: "Liberty* injustice for all*",
    description:
      "An American flag is progressively dyed until it is totally black at the end. Foucault’s Imperial" +
      " Boomerang posits that governments which develop and deploy new technologies for colonial repression will eventually" +
      " use the same methods against its own citizens, this is seen in the United States today with expanded security state" +
      " and domestic use police tactics formerly reserved for “export” to allied regimes. American illegitimacy is overwritten" +
      " by a solid black flag, which has long signified a world without borders and tyrannical governments.",
  },
  {
    img: Covid,
    year: "2021",
    form: "Screen-print on black nylon fabric",
    size: '36" x 60"',
    title: "New Florida Flag",
    description:
      "This work was created in the wake of to Rebekah Jones’ allegations that she was fired for refusing to" +
      " manipulate the COVID-19 data for the state of Florida. The QR code on the flag leads to her" +
      " FloridaCovidAction website. This work is a response to the DeSantis administration’s gross mishandling of" +
      " the pandemic. From a virtually nonexistent lockdown at the beginning of 2020, to withholding funding to" +
      " school districts with mask mandates, to emboldening vaccine hesitancy, the administration has made it" +
      " clear that it does not care about the many people who will be permanently affected by the COVID-19 virus.",
  },
  {
    img: Bastards,
    year: "2021",
    form: "Screen-print, heat foil, black flocking",
    size: "12” x 10”",
    title: "Bastard Museum (Robber Barren Diptych 1⁄2)",
    description:
      "Bastard Museum challenges the noble goals of self-proclaimed world museums, as stewards of world" +
      " culture. While such world museums go to great lengths to appear as apolitical stewards of art and" +
      " knowledge, this was not the impetus for their founding. The British Museum, like many others of similar" +
      " renown, was created as an ethnographic museum, to showcase the superior nature of English culture and" +
      " the white race over their colonial non-white subjects. The foil attracts the viewer to the aesthetic of the" +
      " image, while showing them a reflection of themselves. The clashing, bright, and shiny aspects compete for" +
      " attention over the text, just as the variety of artifacts distract from the original sins of the British Museum.",
  },
  {
    img: Bananas,
    year: "2021",
    form: "Screen-print on red, yellow, and blue nylon",
    size: '60" x 36"',
    title: "Flag for Former Banana Republics",
    description:
      " Translation: Liberate everyone! Build communities not companies!" +
      " American imperialism in Latin America was carried out to increase the profits of large corporations." +
      " The United Fruit Company, as a result, was able to commit atrocities such as the 1928 Banana Massacre and" +
      " lobby the US government to carry out 1954 Guatemalan coup d'état. The company is now known as" +
      " Chiquita Brands International, and as recently as 2016 was still embroiled in a class action lawsuit for" +
      " funding terrorism in Columbia. Their “mascot” Chiquita also is an Anglo-idealization of the “exotic” " +
      " Latina, which imparts harmful ideals of Latina femininity and physical characteristics both implicitly" +
      " through branding and explicitly through “Miss Chiquita” beauty pageants. In this piece the character rejects" +
      " her imperial role in favor of freedom for all peoples in Latin America.",
  },
  {
    img: NoFats,
    title: "No fats, No femmes, No Asians",
    year: "2021",
    form: "Screen-print with silver foil on 80lb cardstock",
    size: '14" x 11"',
    description:
      "This work was made in collaboration with Trent Doyle (model), Charlotte Holly (source photographs) and" +
      " Britney Lefever (seamstress). We sought to address the anti-Asian and sexist sentiments that were at the" +
      " root of the Atlanta shooting. Trent, as a Filipino drag-queen pointed out how similar issues were present in" +
      " the gay community as they had encountered the phrase “no fats, no femmes, no Asians” in both work and" +
      " personal settings. The fetishistic type of sexism that is often mixed with anti-Asian racism manifests in" +
      " many ways across communities and must be combated.",
  },
  {
    img: Pepto,
    year: "2021",
    form: "installation, screen-print, soft sculpture",
    size: 'pillows: 3"x8.5", installation: variable',
    title: "Pepto Pillows/ Nausea Nest",
    description:
      "Thirty pillows made of pink textured fleece, stuffing, screen-printed text installed in artist's bathroom." +
      " This work is based upon experiences of both myself and people around me who expressed frustration when" +
      " seeking medical relief for chronic pain or other long-standing issues while being perceived as women." +
      " Many felt as though their legitimate concerns were dismissively attributed to stress or mental health issues." +
      " The pillows were given away to others who expressed having similar experiences.",
  },
  {
    img: Dan,
    year: "2022",
    form: "Risogrpah print based on ink drawing",
    size: "11”x14”",
    title: "Daniel Alan Baker",
    description:
      "Made in Collaboration with Eric Champagne (illustrator) for the 2022 MAPC Print Exchange.",
  },
  {
    img: Wall,
    year: "2021",
    form: "Installation of 50 screen-prints on 80lb cardstock",
    size: 'each print: 10" x 12", installation: variable',
    title: "Putsch Wall 1923/2021",
    description:
      "The printed image is made from a red and blue image of the January 6th attempted coup overlaid atop a" +
      " black and white image of the Beer Hall Putsch. The photographed section of the installation features 20 of" +
      " the 50 prints, and an overturned American flag. History is repeating itself because it benefits existing power" +
      " structures.",
  },
  {
    img: Clay,
    year: "2019",
    form: "unfired clay, performance, collected audio",
    size: "full performance: 2hrs 8min 39 sec",
    title: "Unfired [Melting Pot]",
    trailer: "trailer: 5 min 38 sec",
    link: "https://youtu.be/nB97tG2tbKg",
    description:
      "Unfired [Melting Pot] is a performance exploring how immigrants and their children differently perceive" +
      " America. I begin with a single clay coil and build the mythological “melting pot” that characterizes the" +
      " United States. Meanwhile, Latin-American immigrants tell their stories through audio recordings. As their" +
      " children speak of this country in a disenchanted fashion, the coil-[melting]-pot breaks down. The “melting" +
      " pot” of this country was impossible. In 2022 this work was awarded Grand Prize at Gallery NAT’s Spiel Allein exhibition.",
  },
  {
    img: School,
    year: "2020",
    form: "white clay, digital decal, underglaze, clear glaze",
    size: '14" x 5.4" x 3" and 5.6" x 7" x 2"',
    title: "Public School Purell & Makeup Wipes for Killer KKKops",
    description:
      "This pair of objects points out the irony of seeking personal ‘cleanliness’ in institutions that perpetrate" +
      " violence. Public School Purell references the school-to-prison pipeline and how individual teachers and" +
      " administrators play an active role in exerting state power from controlling how students dress, restricting" +
      " their bodily functions, and even criminalizing student behavior. The Makeup Wipes for Killer KKKops critiques" +
      " the notion that women’s participation in state-violence is “feminist” simply" +
      " because of their presence while" +
      " lacking the ability to see how the justice system reinforces patriarchy.",
  },
  {
    img: Pyramid,
    year: "2021",
    form: "Screen-print, sculpture, plaster, spray paint",
    size: "29”x 18.5”x15” (variable)",
    title: "Re-Make a Pyramid",
    description:
      "This piece is based on a popular colonial myth about a Frenchman who “saved” the pyramids. The western idea of" +
      " locals ‘supposed disregard for their history, propagated collectors’ fervor to acquire monuments and artifacts from the" +
      " ancient world and take them back to the “civilized world” for safekeeping. The sculpture ponders upon how this dynamic is" +
      " replicated in the microcosm of the museum giftshop. For shop patrons to purchase their own miniature replicas history they" +
      " must make the tacit acknowledgement that the museum owns not only these objects but the rights to reproduce them. The individual" +
      " pyramids tessellate into a larger pyramid, from which audience members were prompted to take their own with text that read: " +
      " “Want your own pyramid? Take one!” Many audience members excitedly took from the pyramid, eager to have their own piece of the" +
      " work, while consequently breaking down the sculpture as whole.",
  },
  {
    img: JaJa,
    year: "2023",
    form: "Nylon, cotton, linoleum relief print, plastic beads, found fabric, drypoint",
    size: "29”x 18.5”x15” (variable)",
    title: "Jaja son huaqueria",
    description:
      "Created while an artist in residence at Galleria No Lugar, this multimedia installation is the culmination of" +
      " a month’s long investigation into the conservation, acquisition, and display practices of ‘pre-Colombian’" +
      " artifacts within Quito’s cultural institutions. I visited el Centro Cultural Metropolitano, Museo Casa" +
      " Alabado, el Museo Nacional del Ecuador, and el Centro de Arte Contemporáneo. Oftentimes when" +
      " inquiring about provenance I was told that it was practically non-existent, as much of the artifacts were" +
      " expected to be illegally excavated, and conservationists were often tasked with archiving forgeries, so" +
      " they did not enter the black market. The title is a quote from a cultural worker’s response." +
      " This installation is the culmination of a month’s long investigation into the conservation, acquisition, and" +
      " display practices of ‘pre-Colombian’ artifacts within Ecuador’s cultural institutions. When inquiring" +
      " about provenance I was told that it was practically non-existent, as much of the artifacts were expected to" +
      " be illegally excavated, and conservationists were often tasked with archiving forgeries, so they did not" +
      " enter the black market. The title is a quote from a cultural worker’s response. It translates as: Haha! They" +
      " [ the artifacts] were looted.",
  },
  {
    img: N95,
    year: "2023",
    form: "Relief prints on N95 packaging",
    size: "28” x 12”",
    title: "THINKING OF YOU…",
    description: " ",
  },
  {
    img: Votive,
    year: "2023",
    form: "Plaster casts, 3d printed model, latex mold",
    size: "6” x5.5” x6” each (variable)",
    title: "Votive Extraction",
    description:
      "This piece investigates how the catalog-preservationist agenda of western museums abstracts and reduces" +
      " the objects (and non-objects) to datapoints or aesthetics. Casting the negative of ‘votive figure’ (museum" +
      " No.118564) leaves the impression of all its curves and contours in the plaster, but this simultaneously" +
      " points to its absence.",
  },
  {
    img: Stand,
    year: "2023",
    form: "Resin and sand casts of artist’s hands and feet",
    size: "installation and dimensions variable",
    title: "How do they stand? /Why are Harihara(s) Hand-Less?",
    description:
      "When in museum galleries we pass many statues lacking hands, feet, and sometimes even" +
      " heads, without a second thought. The lack of appendages is usually attributed to age, but" +
      " in reality, many statues have been cut apart to ship only the parts that will fetch the highest" +
      " price in the antiquities black-market or even legitimate auction houses. The feet of the" +
      " Duryodhana (Khmer statue) prominently featured on Sotheby’s 2011 auction catalog were" +
      " still attached to the stone in Prasat Chen at Koh Ker, Cambodia from where it was stolen." +
      " The Harihara statues in the Metropolitan Museum of Art, and The Kimbell Art Museum" +
      " are both missing their hands and were given by notorious arts-trafficker Douglas" +
      " Latchford. The parts “missing” from the British Museum’s* Parthenon statues sit in" +
      " Greece’s Acropolis Museum. Many of these statues are shown to be in motion, and in" +
      " some cases even dancing, how can they move now?",
  },
  {
    img: Parthenon,
    year: "2023",
    form: "Plaster casts, 3d printed model, clay mold",
    size: "8” x14” x8” (variable)",
    title: "Parthenon Plastered [part 1: white, part 2: squishy]",
    description:
      "This piece explores the shortcomings of “digital repatriation”, a buzzword that a few prominent state" +
      " museums have employed in their vocabulary as a compromise, or decent halfway measure to restitution." +
      " While this premise is flawed on its face, it further assumes a degree of accuracy in museum-3d scans that" +
      " does not exist at this time. The British Museum has published a large collection of 3d scanned objects" +
      " online, but many of the scans are incomplete and inaccurate. The Apotheosis of Homer is made of solid" +
      " marble, but the 3d model makes the object appear as if it was made of paper and the sandstone statute of" +
      " Mictlantecuhtli has no bottom appearing hollow. Thinking about the history of plaster as a replicating" +
      " material, errors in the 3d models, and the subsequent “fictive back” that one would have to generate to" +
      " make these objects printable, I made varied iterations of plaster-casts from a 3d print of the Parthenon" +
      " Frieze. Fidelity varies.",
  },
  {
    img: Rodney,
    year: "2023",
    form: "9g aluminum wire, wire mesh, crafting foam, paper mâché, acrylic paint, quilted fabric.",
    size: '39" x 13" x 5" each',
    title: "For Rodney Kelly in the Interim [no handles, to be handled]",
    description:
      "Rodney Kelley is the descendant of the owner of the Gweagal shield and rightful heir. The British" +
      " Museum, where it is being held, has denied calls for restitution to the indigenous peoples of Australia," +
      " and has only offered a meager “long term loan” to one of Australia’s state museums, further dispossessing" +
      " indigenous Australians of their heritage. The shields fashioned are made to the actual dimensions of the" +
      " original, but are clearly not replicas, owing to their materials, my presence in making them and the lack of" +
      " bullet holes. They are intended to occupy the same size of space, but don’t aim to be stand-ins for cultural" +
      " heritage, rather their presence harkens to the absence felt by the indigenous Australians agitating for the" +
      " return of their cultural patrimony.",
  },
  {
    img: Ponder,
    year: "2022",
    form: "Accordion book, cyanotype on bienfang paper, bookboard",
    size: "5” x 14” fully extended",
    title: "[Pondering Pravic in English]",
    description: " ",
  },
  {
    img: Loot,
    year: "2022",
    form: "Screen-print, cyanotype, laser etching, linoleum block, MDF block, heat foil, and emboss.",
    size: '4.5"; x 1080” fully extended, installation variable.',
    title: "Loot, Loot, and More Loot (on a loop)",
    description:
      "There are few works that have had such wealth of ink poured over them as have the Benin Bronzes." +
      " Within the current debate surrounding restitution and repatriation, this now scattered collection of work" +
      " has become an exemplary case study of the colonial violence with which many western museums filled" +
      " their galleries. The now-famous photographs from Capt. Herbert Walker’s diary labels the piles of cast" +
      " metal works as “Loot”, “Loot”, and “More Loot”. The institutions of colonial powers have since" +
      " rebranded under the sharing of “world culture”, but this world culture is ultimately on view for a western" +
      " audience, seeing at the expense of many members of descendant communities who are disenfranchised by" +
      " money and distance from prominent material heritage.",
  },
  {
    img: QuiltOne,
    year: "2021",
    form: "Screen print, soft sculpture, chicken-wire, bubble wrap",
    size: '19" x 117" x 117"',
    title: "Stolen Objects Quilt (RED 1/3)",
    description:
      "Many western and imperial museums that purport to be world museums display and hold art objects from" +
      " cultures and peoples that were colonized. A quilt is often an intimate stitching of images and designs that reflect" +
      " a culture. However, when tiles, usually filled with personal and cultural significance, are replaced with the taxonomy" +
      " of stolen cultural patrimony, its typical nature is replaced with the sinking reality of these world museums." +
      " These objects have not been allowed to age within their culture and function organically. This quilt, through joining" +
      " together the images of stolen objects highlights how decontextualization is violence and places their stolen lives" +
      " within a large network of shared experiences.",
  },
  {
    img: QuiltTwo,
    year: "2021",
    form: "Screen print, soft sculpture, Spanish moss",
    size: '19" x 117" x 117"',
    title: "Stolen Objects Quilt (YELLOW 2/3)",
    description:
      "The printed artifacts on this quilt create an amalgamation of color and shape that harkens to the illicit" +
      " acquisition of antiquities from auctions houses to museums and private collectors, as well as those acquisitions made" +
      " licit only through time. The quilt is placed atop a thick bed of Spanish moss˗˗the fabled shipping material of the early" +
      " Spanish empire˗˗with a large empty spot in the middle, sinking in its center. The emptiness at its center is a marker of" +
      " the significant absence felt by formerly colonized peoples, as well as the physical absence left behind when artifacts are" +
      " excavated, removed from shrines, or taken from burial sites",
  },
  {
    img: QuiltThree,
    year: "2021",
    form: "Screen print, soft sculpture, 3D prints.",
    size: '19" x 117" x 117"',
    title: "Stolen Objects Quilt (BLUE 3/3)",
    description:
      "This quilt is composed of objects that were, or are planned to be, repatriated while 3D printed iterations of objects" +
      " still within museums are placed upon it. The images on the quilt are printed with high visual detail as it is often with increased" +
      " information and provenance research that the true history of an object is revealed to be illicit or unjustifiable. Museums scramble" +
      " to find agreeable “halfway measures” to restitution such as permanent loans or digital repatriation. To replicate the inauthentic nature" +
      " of digital repatriation, each of the models are made to be gaudy and rendered with poor detail. This work calls attention to the shortcomings" +
      " of successful instances of restitution and repatriation by showing the viewer the diverging paths that museums, auction houses, and" +
      " governments may take to avoid a full and complete return of cultural heritage to their rightful heirs.",
  },
  {
    img: Pervert,
    year: "2021",
    form: "Screen-print, yellow flocking, glitter",
    size: "16.5” x 14”, 26” x 23” framed",
    title: "Perverse Mausoleum (Robber Barren Diptych 2/2)",
    description:
      "Perverse Mausoleum speaks to the racist nature of the preservation of Black human remains as part of museum collections. In 2021," +
      " Penn State Museum finally addressed the Morton collection of crania likely taken from enslaved people and stolen from the graves of the Philadelphia" +
      " Almshouse. Later, it was revealed they were also in possession of the remains of Delisha and Tree Africa, victims of the city’s bombing of MOVE on" +
      " May 13, 1985. Flocking and glitter are used to implicate how the decoration of these people’s remains as “specimens” is dehumanizing, especially" +
      " considering the white supremacist state-sanctioned violence that caused their death. It invites the viewer to look at the material revealing the text.",
  },
  {
    img: Woman,
    year: "2024",
    form: "Image 17 x 24 in on 22 x 30in cream rives bfk.",
    title: "Woman Figurine, Isdud",
    description: " ",
  },
  {
    img: Wire,
    year: "2023",
    form: "Wire-O bound & reproduced with cyanotype prints on rives bfk.",
    size: "6in x 4.25in",
    title: "Recipes by Amada F. Reinoso Zambonino / Transcribed by Samuel E. Reinoso Ortiz",
    description: " ",
  },
  {
    img: Amanda,
    year: "2023",
    form: "Stab bound with black cover and light blue thread. Reproduced with cyanotype prints on rives bfk.",
    size: "8.5 in x 5.5 in",
    title: "Recipes by Amada F. Reinoso Zambonino/ / Transcribed by Samuel E. Reinoso Ortiz",
    description: " ",
  },
  {
    img: DryPoint,
    year: "2023",
    form: "Akua ink on rives bfk. Carries mark of Estampería Quiteña archive",
    size: "9 x 7 in each",
    title: "Installation of Drypoint Prints from “Jaja son huaqeria”",
    description: " ",
  },
  {
    img: DryPointPrint,
    year: "2023",
    form: "Akua ink on rives bfk. Carries mark of Estampería Quiteña archive",
    size: "9 x 7 in.",
    title: "Drypoint Print from “Jaja son huaqeria”",
    description: "",
  },
  {
    img: Rolleers,
    year: "2023",
    form: "Audience participation , acrylic paint, brown paper, stamp rollers cast from sand & liquid plastic with wood dowels," +
    "and  linoleum stamps from “Jaja son huaqeria”. ",
    size: "Rollers: 3.5 in x 6 in max.",
    title: "Towards an authentic (?) forgery (?)",
    description: "In placing aspects of cultural heritage in vitrines, they are separated from their context, community and function for the purpose of statecraft. In the Casa Alabado Museum I came upon several “pre-columbian” ceramic pieces that are thought to have been used as stamp rollers. This participatory work seeks to reimbue similar objects with the function that those in museum collections are deprived of.  This questions the object-centered  nature of preservation," +
    "and points to possibilities of conservation beyond institutions that involve community and function",
  },
  {
    img: Mimi,
    year: "2023",
    form: "Cyanotype cover on rives bfk. Stab-bound with light blue thread.  Reproduction on Oce-transparency pages",
    size: "6in x 4.25in",
    title: "Mini: Recipes by Amada F. Reinoso Zambonino / Transcribed by Samuel E. Reinoso Ortiz",
    description: "",
  },
  
];

export default Portfilio;
