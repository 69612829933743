import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material/';
import BG from '../Assets/artitstStatementFinal.gif';
import '../App.css';


function ArtistStatement() {

  const [paperWidth, setPaperWidth] = useState('50%');

  useEffect(() => {
    // Update the paper width based on the screen size
    function handleResize() {
      if (window.innerWidth <= 768) { // Adjust this value as needed
        setPaperWidth('20%'); // Set a different width for small screens
      } else {
        setPaperWidth('50%'); // Set the default width for larger screens
      }
    }

    // Initial call to set the initial width
    handleResize();

    // Add a listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollableStyle = {
    maxHeight: '300px',
    width: '400px',
    height: '500px',
    overflowY: 'scroll',
    margin: 'auto',
  };

  const styling = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${BG})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',

    // Responsive Styling
    '@media (max-width: 768px)': {
      height: '100vh',
    },
    '@media (max-width: 480px)': {
      height: '100vh',
    },
  };

  const headerStyle = {
    color: '#5448C8',
    fontFamily: 'ThereBrat',
  };

  return (
    <div>
      <Box sx={styling}>
        <Typography variant='h1' style={headerStyle}>
          Artist Statement
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            paddingLeft: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
          }}
        >
          <Paper style={{ ...scrollableStyle, margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: paperWidth, height: '50%' }}>
            <p style={{ padding: '0 20px', fontFamily: "Montserrat, sans-serif", }}>
                My work addresses the interconnectedness of colonialism and fascism through their legacies within contemporary intuitions. The self-perpetuation of institutions inspires me to work in multiples, 
                primarily in print where there is no differentiation between an original and reproduction. I work toward an in-depth examination of institutional acquisition methods, display, and responses to 
                calls for restitution. By recontextualizing items held in museum collections deprived of the life their original stewards intended, I embrace variation to point out the false imposition of 
                neutrality upon artifacts held in museums.My work encourages interaction, sometimes asking the audience to take part of the piece with them, making the viewer both its preservationist and destroyer. 
                This lets the artwork exist beyond the static art-object relegated to the gallery. My hope is for the audience to experience the work personally so that we may abandon the colonial gallery holding 
                fetishized art[ifacts].  

            </p>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}

export default ArtistStatement;
